import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { FieldView } from '../shared/config-response';

@Component({
  selector: 'app-body-field',
  templateUrl: './body-field.component.html',
  styleUrls: ['./body-field.component.css']
})
export class BodyFieldComponent implements OnInit {

  @Input() fields: Array<string> = [];
  @Input() doc: any;
  @Input() item?: FieldView;

  body_text: string = "";

  @HostBinding("style.--max-lines")
  max_lines: number = 4;

  constructor() { }

  ngOnInit(): void {
    const found = this.fields.find(field => {
      return (field in this.doc);
    });
    if (found) {
      if (this.item && this.item['max_lines']) {
        this.max_lines = this.item['max_lines'];
      }
      this.body_text = this.doc[found];
    }
  }
}