<div class="blue-background"></div>
<div class="container">
    <mat-card class="card">
        <div class="login-logo" [ngStyle]="{
    'background':'url(/api/swirl/sapi/branding/?target=light)',
     'background-size': 'contain',
      'background-position': 'center',
      'background-repeat': 'no-repeat'}"></div>
        <h1>You have been locked out as a result of too many failed LogIn attempts. Please contact a system administrator to reset your attempts counter.</h1>
    </mat-card>
</div>
