import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.css']
})
export class NumberFieldComponent implements OnInit {

  @Input() fields: Array<string> = [];
  @Input() doc: any;

  value?: number;

  constructor() { }

  ngOnInit(): void {
    const found = this.fields.find(field => {
      return (field in this.doc);
    });
    if(found) {
      this.value = parseInt(this.doc[found]);
    }
  }

}
