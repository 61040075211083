<div class="row" *ngIf="config.cart.enabled">
  <div class="col"></div>
  <a class="col-auto align-self-end text-right" style="cursor: pointer" (click)="toggleCart()" [title]="cartText">
    <span *ngIf="!cartImage">{{cartText}}</span>
    <img *ngIf="cartImage" [src]="cartImage">
  </a>
</div>
<div *ngIf="layout.summary" class="summary">
  <div class="roww" *ngFor="let row of layout.summary">
    <div class="col" [class.col-5]="i%2===1 && lists.length > 1" [class.col-7]="i%2===0 && lists.length > 1" [class.col-12]="lists.length===1" *ngFor="let lists of row; let i = index;">
      <dl class="mb-4" *ngFor="let list of lists">
        <div class="row w-100 type-{{item.type}} {{item.custom_field_type}}" [attr.data-fields]="item.fields" [style.color]="item.color ? item.color: ''"
             [style.font-style]="item.font_style ? item.font_style: ''" *ngFor="let item of list; let ind = index">
          <ng-template [ngIf]="!item['hidden_values'] || (doc[item.fields[0]] && !item['hidden_values'].includes(doc[item.fields[0]][0]))">
            <ng-template [ngIf]="item.type === 'google'">
              <dt class="col">
                <app-google-field [fields]="item.fields" [label]="item.label" [doc]="doc"></app-google-field>
              </dt>
            </ng-template>
            <ng-template [ngIf]="item.type === 'integer' && doc[item.fields[0]]">
              <dt class="col col-md-auto text-nowrap">{{item.label}}:</dt>
              <app-number-field [fields]="item.fields" [doc]="doc"></app-number-field>
            </ng-template>
            <ng-template [ngIf]="item.type === 'currency' && doc[item.fields[0]]">
              <dt class="col col-md-auto text-nowrap">{{item.label}}:</dt>
              <app-currency-field [fields]="item.fields" [doc]="doc" [item]="item"></app-currency-field>
            </ng-template>
            <ng-template [ngIf]="item.type === 'date' && doc[item.fields[0]]">
              <dt class="summary col col-md-auto text-nowrap">{{item.label}}:</dt>
              <app-date-field [fields]="item.fields" [doc]="doc" [item]="item"></app-date-field>
            </ng-template>
            <ng-template [ngIf]="item.type === 'link'">
              <app-link-field [fields]="item.fields" [doc]="doc" [item]="item"></app-link-field>
            </ng-template>
            <ng-template [ngIf]="item.type === 'json' && (doc[item.fields[0]] | json) != '{}'">
              <ng-template [ngIf]="item.label">
                <dt class="col col-md-auto text-nowrap">{{item.label}}:</dt>
              </ng-template>
              <app-json-field [fields]="item.fields" [doc]="doc" [item]="item" style="margin-left: 16px; margin-top: -25px"></app-json-field>
            </ng-template>
            <!-- <ng-template [ngIf]="(item.type === 'string') && doc[item.fields[0]]">
              <app-string-field [fields]="item.fields" [doc]="doc" [item]="item" [highlight]="highlight" style="position: absolute; top: -80px; right: 0;"></app-string-field>
            </ng-template> -->
            <ng-template [ngIf]="item.type === 'authorship'">
              <app-authorship-field [fields]="item.fields" [doc]="doc" [item]="item" [ngClass]="{ 'null-payload-margin': !isPayload, 'payload-margin': isPayload }"></app-authorship-field>
            </ng-template>
            <ng-template [ngIf]="item.type === 'title'">
              <input *ngIf="isCheckboxRendered" type="checkbox" [(ngModel)]="isChecked" (change)="check()" style="margin-left: -5px; margin-right: 5px; width: 16px; cursor: pointer;">
              <app-title-field [fields]="item.fields" [doc]="doc" [item]="item" style="max-width: 87%;"></app-title-field>
            </ng-template>
            <ng-template [ngIf]="item.type === 'body' && doc[item.fields[0]]">
              <app-body-field [fields]="item.fields" [doc]="doc" [item]="item" style="margin-left:16px"></app-body-field>
            </ng-template>
            <ng-template [ngIf]="(item.type === 'text') && doc[item.fields[0]]">
              <ng-template ngFor let-field [ngForOf]="item.fields" let-j="index">
                <span *ngIf="j==0 && (!highlight || !highlight[field] || !highlight[field][0])">{{doc[field]}}</span>
                <span *ngIf="j==0 && highlight && highlight[field] && highlight[field][0]" [innerHTML]="highlight[field][0]"></span>
                <span *ngIf="j>0"> ({{doc[field]}})</span>
              </ng-template>
            </ng-template>
            <ng-template [ngIf]="item.type === 'custom'">
              <app-custom-field class="w-100" [fields]="item.fields" [doc]="doc" [item]="item" [highlight]="highlight"></app-custom-field>
            </ng-template>
            <ng-template [ngIf]="item.type === 'authorship' && doc['swirl_score'] > minimumSwirlScore">
              <img class="star" src="assets/images/star.svg"/>
            </ng-template>
          </ng-template>
        </div>
      </dl>
    </div>
  </div>
</div>
<div *ngIf="layout.details && showDetails" class="details">
  <div class="row" *ngFor="let row of layout.details">
    <div class="col" [class.col-5]="i%2===1 && lists.length > 1" [class.col-7]="i%2===0 && lists.length > 1" [class.col-12]="lists.length===1" *ngFor="let lists of row; let i = index;">
      <dl class="mb-4" *ngFor="let list of lists">
        <div class="row" [style.color]="item.color ? item.color: ''"
             [style.font-style]="item.font_style ? item.font_style: ''" *ngFor="let item of list">
          <ng-template [ngIf]="item.type === 'google'">
            <dt class="col">
              <app-google-field [fields]="item.fields" [label]="item.label" [doc]="doc"></app-google-field>
            </dt>
          </ng-template>
          <ng-template [ngIf]="item.type === 'integer' && doc[item.fields[0]]">
            <dt class="col col-md-auto text-nowrap">{{item.label}}:</dt>
            <app-number-field [fields]="item.fields" [doc]="doc"></app-number-field>
          </ng-template>
          <ng-template [ngIf]="item.type === 'currency' && doc[item.fields[0]]">
            <dt class="col col-md-auto text-nowrap">{{item.label}}:</dt>
            <app-currency-field [fields]="item.fields" [doc]="doc" [item]="item"></app-currency-field>
          </ng-template>
          <ng-template [ngIf]="item.type === 'date' && doc[item.fields[0]]">
            <dt class="col col-md-auto text-nowrap">{{item.label}}:</dt>
            <app-date-field [fields]="item.fields" [doc]="doc" [item]="item"></app-date-field>
          </ng-template>
          <ng-template [ngIf]="(item.type === 'string') && doc[item.fields[0]]">
            <app-string-field [fields]="item.fields" [doc]="doc" [item]="item" [highlight]="highlight"></app-string-field>
          </ng-template>
          <ng-template [ngIf]="item.type === 'json' && (doc[item.fields[0]] | json) != '{}'">
            <dt *ngIf="item.label" class="col col-md-auto text-nowrap">{{item.label}}:</dt>
            <app-json-field [fields]="item.fields" [doc]="doc" [item]="item" style="margin-left: 16px; margin-top: -25px"></app-json-field>
          </ng-template>
          <ng-template [ngIf]="item.type === 'link'">
            <app-link-field [fields]="item.fields" [doc]="doc" [item]="item"></app-link-field>
          </ng-template>
          <ng-template [ngIf]="(item.type === 'text') && doc[item.fields[0]]">
            <ng-template ngFor let-field [ngForOf]="item.fields" let-j="index">
              <span *ngIf="j==0 && (!highlight[field] || !highlight[field][0])">{{doc[field]}}</span>
              <span *ngIf="j==0 && highlight[field] && highlight[field][0]" [innerHTML]="highlight[field][0]"></span>
              <span *ngIf="j>0"> ({{doc[field]}})</span>
            </ng-template>
          </ng-template>
          <ng-template [ngIf]="item.type === 'custom' && doc[item.fields[0]]">
            <app-custom-field [fields]="item.fields" [doc]="doc" [item]="item" [highlight]="highlight"></app-custom-field>
          </ng-template>
        </div>
      </dl>
    </div>
  </div>
</div>
<div class="row" *ngIf="layout.details">
  <div class="col"></div>
  <a class="col-auto align-self-end text-right" style="cursor: pointer" (click)="toggleDetails()">{{toggleText}}</a>
</div>
