import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SearchService} from '../search.service';
import {SearchContext} from '../shared/search-context';
import { SwirlService } from '../swirl.service'

@Component({
  selector: 'app-sort-search',
  templateUrl: './sort-search.component.html',
  styleUrls: ['./sort-search.component.css']
})
export class SortSearchComponent implements OnInit {

  sortForm: FormGroup;
  searchContext: SearchContext = new SearchContext();
  sortings = [
    {value: 'RelevancyMixer', name: 'Relevancy'},
    {value: 'DateMixer', name: 'Date'},
    {value: 'RoundRobinMixer', name: 'Top pick'}
  ];
  isLoading: boolean;
  isShowing: boolean;
  numFound: number;


  constructor(private fb: FormBuilder, private searchService: SearchService, private swirl: SwirlService) {
    this.sortForm = this.fb.group({
      selectedSorting: [this.searchService.getFilter('result_mixer')?.split(':')?.[1], Validators.required]
    });
    this.isLoading = false;
    this.isShowing = false;
    this.numFound = 0;
  }

  ngOnInit(): void {
    this.searchService.isLoading.subscribe(isLoading => this.isLoading = isLoading);
    this.searchService.isSearched.subscribe(isSearched => this.isShowing = isSearched)
    this.searchService.currentResults.subscribe(results => {
      this.numFound = results.numFound;
    });
    this.searchService.eraseEvent.subscribe((isErased) => {
      if(isErased)
        this.searchService.putFilterWithoutSearch('result_mixer', `result_mixer:${this.sortings[0].value}`);
    })
  }

  onSelectionChange(event: any): void {
    let filter: string = event.value
    this.searchService.clearSelectedItems()
    if (filter == null) {
      this.searchService.removeFilter('result_mixer');
    } else {
      this.searchService.putFilter('result_mixer', `result_mixer:${filter}`);
    }
    if (filter !== null) {
      const selectedSortingControl = this.sortForm.get('selectedSorting');
      if (selectedSortingControl) {
        selectedSortingControl.setValue(filter);
      }    
    }
  }
}
