import {Component, Input, OnInit} from '@angular/core';
import {FieldView} from "../shared/config-response";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.css']
})
export class DateFieldComponent implements OnInit {

  @Input() fields: Array<string> = [];
  @Input() doc: any;
  @Input() item?: FieldView;

  value?: string;

  format: string = 'M/d/yyyy';
  timezone: string = 'en-us';

  constructor() { }

  ngOnInit(): void {
    if(this.item && this.item['format']) {
      this.format = this.item['format'];
    }
    if(this.item && this.item['timezone']) {
      this.timezone = this.item['timezone'];
    }
    const found = this.fields.find(field => {
      return (field in this.doc);
    });
    if(found) {
      var val: string = this.doc[found];

      if (val == 'unkown') {
        this.value = undefined;
      }

      this.value = formatDate(val + 'Z', this.format, this.timezone);
    }
  }

}
