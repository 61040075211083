import { OnInit } from '@angular/core';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart } from "chart.js/auto";
import { LocalStorageService } from '../local-storage.service';
import { SearchService } from '../search.service';
import { Result } from '../shared/result';

@Component({
  selector: "galaxy-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.css"]
})
export class GalaxyChart implements AfterViewInit, OnInit {

  @ViewChild('canvas') canvas?: ElementRef;

  chart?: Chart;
  canShow: boolean = false;
  isLoading: boolean = true;
  mode: string = localStorage.getItem('isDark') === 'Dark' ? 'Dark' : 'Light'
  legend = {
    labels: {
      color: this.mode === "Dark" ? "rgba(255, 255, 255, 0.5)" : "#666"
    }
  }

  result: Result = new Result()

  backgroundColors = [
    'rgba(255, 99, 132, 0.7)',
    'rgba(22, 160, 133, 0.7)',
    'rgba(241, 196, 15, 0.7)',
    'rgba(211, 84, 0, 0.7)',
    'rgba(41, 128, 185, 0.7)',
    'rgba(142, 68, 173, 0.7)',
    'rgba(44, 62, 80, 0.7)',
    'rgba(39, 174, 96, 0.7)',
    'rgba(192, 57, 43, 0.7)',
    'rgba(127, 140, 141, 0.7)',
    'rgba(255, 99, 132, 0.7)',
    'rgba(22, 160, 133, 0.7)',
    'rgba(241, 196, 15, 0.7)',
    'rgba(211, 84, 0, 0.7)',
    'rgba(41, 128, 185, 0.7)',
    'rgba(142, 68, 173, 0.7)',
    'rgba(44, 62, 80, 0.7)',
    'rgba(39, 174, 96, 0.7)',
    'rgba(192, 57, 43, 0.7)',
    'rgba(127, 140, 141, 0.7)',
  ]

  hoverBackgroundColors = [
    'rgba(255, 99, 132, 1)',
    'rgba(22, 160, 133, 1)',
    'rgba(241, 196, 15, 1)',
    'rgba(211, 84, 0, 1)',
    'rgba(41, 128, 185, 1)',
    'rgba(142, 68, 173, 1)',
    'rgba(44, 62, 80, 1)',
    'rgba(39, 174, 96, 1)',
    'rgba(192, 57, 43, 1)',
    'rgba(127, 140, 141, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(22, 160, 133, 1)',
    'rgba(241, 196, 15, 1)',
    'rgba(211, 84, 0, 1)',
    'rgba(41, 128, 185, 1)',
    'rgba(142, 68, 173, 1)',
    'rgba(44, 62, 80, 1)',
    'rgba(39, 174, 96, 1)',
    'rgba(192, 57, 43, 1)',
    'rgba(127, 140, 141, 1)'
  ]

  constructor(private searchService: SearchService, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.localStorageService.localStorageChanged.subscribe((value: string) => {
      this.mode = value === 'Dark' ? 'Dark' : 'Light'
      this.updateViewForMode()
    });
    this.searchService.eraseEvent.subscribe((isErased) => {
      if(isErased) {
        this.canShow = false
        this.result = new Result()
      }
    })
    this.searchService.isLoading.subscribe((isLoading) => this.isLoading = isLoading)
  }

  updateViewForMode() {
    if (this.chart?.config.options?.scales) {
      let x: any = this.chart.config.options.scales.x;
      let y: any = this.chart.config.options.scales.y;
      this.chart.data.datasets.forEach((dataset) => {
        dataset.backgroundColor = this.backgroundColors
        dataset.hoverBackgroundColor = this.hoverBackgroundColors
      });
      if (this.mode === "Dark") {
        x.grid.color = 'rgba(255, 255, 255, 0.5)';
        y.grid.color = 'rgba(255, 255, 255, 0.5)';
        x.ticks.color = 'rgba(255, 255, 255, 0.5)';
        y.ticks.color = 'rgba(255, 255, 255, 0.5)';
        x.title.color = 'rgba(255, 255, 255, 0.5)';
        y.title.color = 'rgba(255, 255, 255, 0.5)';
      } else {
        x!.grid!.color = 'rgba(0, 0, 0, 0.1)';
        y!.grid!.color = 'rgba(0, 0, 0, 0.1)';
        x.ticks.color = '#666';
        y.ticks.color = '#666';
        x.title.color = '#666';
        y.title.color = '#666';
      }
    }
    this.chart?.update()
  }

  handleResize() {
    for (let id in Chart.instances) {
      Chart.instances[id].resize();
    }
  }

  ngAfterViewInit(): void {
    this.searchService.currentResults.subscribe(result => {
      this.result = result
      for (let i = 0; i < this.result.docs.length; i++) {
        if ('dataset' in this.result.docs[i]['payload']) {
          let data: any[] = []
          this.extractData(this.result.docs[i]['payload']['dataset'], data);
          let func = this.checkSupported(data);
          if (func == null) {
            this.chart?.destroy();
            this.canShow = false
            return
          } else {
            this.canShow = true
          }

          func(data);
          this.updateViewForMode();
          return
        }
      }

      this.chart?.destroy();
      this.canShow = false;
    });
  }


  drawBar(data: any[]) {
    let keys = Object.keys(data[0])
    let stringKey: string;
    let valueKey: string;
    if (typeof keys[0] == 'string') {
      stringKey = keys[0];
      valueKey = keys[1];
    } else {
      stringKey = keys[1];
      valueKey = keys[0];
    }

    if (this.chart)
      this.chart.destroy()
    this.chart = new Chart(this.canvas?.nativeElement, {
      type: "bar",
      data: {
        labels: data.map(obj => obj[stringKey]),
        datasets: [{
          label: valueKey,
          data: data.map(obj => obj[valueKey])
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        responsive: true,
        scales: {
          y: {
            title: {
              display: true,
              text: valueKey
            },
            ticks: {
              maxTicksLimit: 6
            }
          },
          x: {
            title: {
              display: true,
              text: stringKey
            },
            grid: {
              display: false
            }
          }
        }
      }
    })
  }

  drawStacked(data: any[]) {
    let keys = Object.keys(data[0])
    let valueVar: string = "";
    keys.forEach(key => {
      if (typeof data[0][key] === "number") {
        valueVar = key;
      }
    });
    let temp = keys.filter(key => key != valueVar);
    let categoryVar = temp[0];
    let datasetVar = temp[1];


    let uniqueDatasets = new Set<string>();
    let uniqueCategories = new Set<string>();
    data.forEach(obj => {
      uniqueDatasets.add(obj[datasetVar]);
      uniqueCategories.add(obj[categoryVar]);
    });

    let map: any = {}
    let catList = Array.from(uniqueCategories.keys());

    uniqueDatasets.forEach(d => {
      let def: any = {}
      uniqueCategories.forEach(cat => def[cat] = 0);
      map[d] = def;
    });

    data.forEach(obj => map[obj[datasetVar]][obj[categoryVar]] = obj[valueVar]);

    let dataset: any[] = []
    uniqueDatasets.forEach(val => dataset.push(
      {
        label: val,
        data: catList.map(cat => map[val][cat]),
        stack: val
      }
    ));
    if (this.chart)
      this.chart.destroy()
    this.chart = new Chart(this.canvas?.nativeElement, {
      type: "bar",
      data: {
        labels: catList,
        datasets: dataset
      },
      options: {
        plugins: {
          legend: this.legend
        },
        responsive: true,
        scales: {
          y: {
            stacked: true,
            title: {
              display: true,
              text: valueVar
            }
          },
          x: {
            stacked: true,
            title: {
              display: true,
              text: categoryVar
            }
          }
        }
      }
    })
  }

  checkSupported(data: any[]): CallableFunction | null {
    if (data.length == 0) {
      return null;
    }

    let count = 0;
    let numKeys = Object.keys(data[0]).length;
    Object.keys(data[0]).forEach(key => count += typeof data[0][key] === 'number' ? 1 : 0);

    if (count === 0) {
      if (numKeys === 3) {
        let key = Object.keys(data[0])[0]
        data.map(obj => delete obj[key])
      }
      data.map(d => d["[count]"] = 1);
      if (numKeys == 1) {
        return this.drawBar.bind(this);
      } else if (numKeys == 2 || numKeys == 3) {
        return this.drawStacked.bind(this);
      }
    } else if (count == 1) {
      if (numKeys == 2) {
        return this.drawBar.bind(this);
      } else if (numKeys == 3) {
        return this.drawStacked.bind(this);
      }
    } else if (count == 2) {
      if (numKeys == 2) {
        return this.drawScatter.bind(this);
      } else if (numKeys == 3) {
        return this.drawScatter.bind(this);
      }
    } else if (count == 3) {
      if (numKeys == 3) {
        return this.drawBubble.bind(this);
      }
    }

    return null;
  }

  drawBubble(data: any[]) {
    let continous = Object.keys(data[0])

    let scaleFactor = Math.max(...data.map(obj => obj[continous[2]])) / this.canvas?.nativeElement.width * 10;

    if (this.chart)
      this.chart.destroy()
    this.chart = new Chart(this.canvas?.nativeElement, {
      type: "bubble",
      data: {
        datasets: [{
          label: "dataset",
          data: data.map(obj => {
            let temp: any = {}
            temp["x"] = obj[continous[0]];
            temp["y"] = obj[continous[1]];
            temp["r"] = obj[continous[2]] / scaleFactor;
            return temp;
          })
        }]
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: any) => {
                return continous[0] + ": " + context.raw.x.toString() + "\n" +
                  continous[1] + ": " + context.raw.x.toString() + "\n" +
                  continous[2] + ": " + (context.raw.r * scaleFactor).toString();
              }
            }
          },
          legend: this.legend
        },
        scales: {
          x: {
            title: {
              display: true,
              text: continous[0]
            }
          },
          y: {
            title: {
              display: true,
              text: continous[1]
            }
          }
        }
      }

    });
  }

  drawScatter(data: any[]) {
    let continous = Object.keys(data[0]).filter(key => typeof data[0][key] === "number");
    let discrete = Object.keys(data[0]).filter(key => !continous.includes(key));
    let innerData: any;
    if (discrete.length == 0) {
      innerData = {
        datasets: [
          {
            label: "dataset",
            data: data.map(obj => {
              let temp: any = {};
              temp["x"] = obj[continous[0]];
              temp["y"] = obj[continous[1]];
              return temp;
            })
          }
        ]
      }
    } else {
      let unique = new Set()
      data.forEach(d => unique.add(d[discrete[0]]));
      innerData =
      {
        datasets: Array.from(unique.keys()).map(dataset => {
          return {
            label: dataset,
            data: data.filter(obj => obj[discrete[0]] === dataset).map(obj => {
              {
                let temp: any = {};
                temp["x"] = obj[continous[0]];
                temp["y"] = obj[continous[1]];
                return temp;
              }
            })
          }
        })
      }
    }


    if (this.chart)
      this.chart.destroy()
    this.chart = new Chart(this.canvas?.nativeElement, {
      type: "scatter",
      data: innerData,
      options: {
        responsive: true,
        plugins: {
          legend: this.legend
        },
        scales: {
          x: {
            title: {
              display: true,
              text: continous[0]
            }
          },
          y: {
            title: {
              display: true,
              text: continous[1]
            }
          }
        }
      }

    });
  }

  extractData(dataset: any[][], data: any[]) {
    for (let i = 0; i < dataset.length; i += 2) {
      let newItem: any = {}
      for (let j = 0; j < dataset[i].length; j++) {
        newItem[dataset[i][j]] = dataset[i + 1][j]
      }
      data.push(newItem)
    }
  }

}
