import {Component, Injectable, Type} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {
  private fields: Map<string, Type<any>>;

  constructor() {
    this.fields = new Map<string, Type<any>>();
  }

  putFieldComponent(field: string, component: Type<any>): void {
    this.fields.set(field, component);
  }

  hasField(field: string): boolean {
    return this.fields.has(field);
  }

  getFieldComponent(field: string): Type<any> | undefined {
    return this.fields.get(field);
  }
}
