import { Component, OnInit, Input } from '@angular/core';
import { FieldView } from '../shared/config-response';

@Component({
  selector: 'app-authorship-field',
  templateUrl: './authorship-field.component.html',
  styleUrls: ['./authorship-field.component.css']
})
export class AuthorshipFieldComponent implements OnInit {

  @Input() fields: Array<string> = [];
  @Input() doc: any;
  @Input() item?: FieldView;

  provider: string = "";
  providerRank: number = 0;
  author: string = "";
  url: string = "";
  date: string | null | undefined = "";

  constructor() { }

  ngOnInit(): void {
    if (this.doc[this.fields[0]]) {
      this.provider = this.doc[this.fields[0]];
    }
    if (this.doc[this.fields[1]]) {
      this.providerRank = this.doc[this.fields[1]];
    }
    if (this.doc[this.fields[2]]) {
      this.author = this.doc[this.fields[2]];
    }
    if (this.doc[this.fields[3]]) {
      this.url = this.doc[this.fields[3]];
    }
  }

}
