export class Facets {
  count: number;
  [index: string]: number | Facet;

  constructor() {
    this.count = 0;
  }
}

export class Facet {
  buckets: Array<Bucket>;
  missing: Bucket | undefined;
  count: number | undefined;

  constructor() {
    this.buckets = [];
  }
}

export class Bucket {
  val: string;
  label: string;
  count: number;
  checked: string;
  filtered: boolean;
  [index: string]: string | number | Facet | boolean;

  constructor() {
    this.val = '';
    this.label = '';
    this.count = 0;
    this.checked = '';
    this.filtered = false;
  }

}
