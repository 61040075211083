import {Component, Input, OnInit} from '@angular/core';
import {FieldView} from "../shared/config-response";

@Component({
  selector: 'app-link-field',
  templateUrl: './link-field.component.html',
  styleUrls: ['./link-field.component.css']
})
export class LinkFieldComponent implements OnInit {

  @Input() fields: Array<string> = [];
  @Input() doc: any;
  @Input() item?: FieldView;

  linkLabel?: number;
  linkUrl?: number;
  linkTarget: string = "_blank";

  constructor() { }

  ngOnInit(): void {
    if (this.item && this.item['link_label_field']) {
      this.linkLabel = this.doc[this.item['link_label_field']];
    }
    if (!this.linkLabel && this.item && this.item['link_label']) {
      this.linkLabel = this.item['link_label'];
    }
    
    if (this.item && this.item['link_url_field']) {
      this.linkUrl = this.doc[this.item['link_url_field']];
    }
    if (!this.linkUrl && this.item && this.item['link_url']) {
      this.linkUrl = this.item['link_url'];
    }
    
    if (this.item && this.item['link_target']) {
      this.linkTarget = this.item['link_target'];
    }
  }

}
