import { ColumnMapping } from './column-mapping';
import {ResultMapping} from './result-mapping';

export class ConfigResponse {
  [index: string]: any;
  index: string;
  df: string;
  defType: string;
  qOp: string;
  qf: Array<string>;
  filters: Array<string>;
  defaultFilters: object;
  separator: string;
  pageSizes: Array<number>;
  resultFields: ResultMapping;
  resultColumns: Array<ColumnMapping>;
  fields: Fields;
  resultLayout: ResultCard;
  jsonFacet: FacetConfig;
  facetLayout: Array<FacetGroup>;
  actions: Array<Action>;
  actionParameterValues: ActionParameterValues;
  cart: CartConfig;

  constructor() {
    this.index = '';
    this.df = '';
    this.defType = '';
    this.qOp = '';
    this.qf = [];
    this.filters = [];
    this.defaultFilters = {};
    this.separator = '';
    this.pageSizes = [];
    this.resultFields = new ResultMapping();
    this.resultColumns = [];
    this.fields = {};
    this.resultLayout = new ResultCard();
    this.jsonFacet = new FacetConfig();
    this.facetLayout = [];
    this.actions = [];
    this.actionParameterValues = new ActionParameterValues();
    this.cart = new CartConfig();
  }
}

export class FacetConfig {
  [index: string]: FieldFacet;
}

export class FieldFacet {
  type: string;
  facetField
  displayField: string;
  reformat?: any;

  constructor() {
    this.type = '';
    this.displayField = '';
    this.facetField = '';
  }
}

export class FacetGroup {
  title: string;
  facets: Array<string>;

  constructor() {
    this.title = '';
    this.facets = [];
  }
}

export class Fields {
  [index: string]: Field;
}

export class Field {
  highlightable: boolean

  constructor() {
    this.highlightable = false;
  }
}

export class ResultCard {
  summary: Array<Array<Array<Array<FieldView>>>>;
  details: Array<Array<Array<Array<FieldView>>>>;

  constructor() {
    this.summary = [];
    this.details = [];
  }
}

export class FieldView {
  [index: string]: any;
  type: string;
  label: string;
  fields: Array<string>;
  color: string;
  font_style: string;

  constructor() {
    this.type = '';
    this.label = '';
    this.fields = [];
    this.color = '';
    this.font_style = '';
  }
}

export class Action {
  label: string;
  id: string;
  url: string;
  target: string;
  parameters: Array<ActionParameters>;
  useSelector: boolean;


  constructor() {
    this.label = '';
    this.id = '';
    this.url = '';
    this.target = '';
    this.parameters = [];
    this.useSelector = false;
  }
}

export class ActionParameters {
  name: string;
  type: string;
  value: string;

  constructor() {
    this.name = '';
    this.type = '';
    this.value = '';
  }
}

export class ActionParameterValues {
  [index: string]: ActionParameterSelector;
}

export class ActionParameterSelector {
  values: Array<ActionParameterValue>;
  taggers: Array<ActionParameterTagger>;

  constructor() {
    this.values = [];
    this.taggers = [];
  }
}

export class ActionParameterTagger {
  label: string;
  tag: string;

  constructor() {
    this.label = '';
    this.tag = '';
  }
}

export class ActionParameterValue {
  label: string;
  value: string;
  checked: string;
  required: boolean;
  tags: Array<string>;

  constructor() {
    this.label = '';
    this.value = '';
    this.checked = '';
    this.required = false;
    this.tags = [];
  }
}

export class CartConfig {
  enabled: boolean;
  addText: string;
  addImage: string;
  removeText: string;
  removeImage: string;

  constructor() {
    this.enabled = true;
    this.addText = '';
    this.addImage = '';
    this.removeText = '';
    this.removeImage = '';
  }
}
