<div class="blue-background"></div>
<div class="container">
  <mat-card class="card">
    <div class="login-logo" [ngStyle]="{
    'background':'url(/api/swirl/sapi/branding/?target=light)',
     'background-size': 'contain',
      'background-position': 'center',
      'background-repeat': 'no-repeat'}"></div>
    <form (submit)="oidcLogin()" *ngIf="is_oauth">
      <div class="login-text-container">
        <span class="login-text">Log into your account:</span>
      </div>
      <div class="login-microsoft-container">
        <button mat-flat-button color="primary" class="submit-button" type="submit">LOG IN WITH MICROSOFT</button>
      </div>
      <div class="divider">
        <span class="divider-text">or continue with email</span>
      </div>
    </form>
    <form class="form" (submit)="login()">
      <div style="width: 22vw; text-align: center; margin-bottom: 20px;" *ngIf="!is_oauth">
        <span class="login-text">Log In</span>
      </div>
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Username</mat-label>
        <input matInput class="mat-login-input" [(ngModel)]="username" name="username" placeholder="Username" required>
      </mat-form-field>
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput class="mat-login-input" [(ngModel)]="password" name="password" type="password" placeholder="Password" required>
      </mat-form-field>
      <a [href]="forgotPasswordLink" target="_blank" class="forgot-password">Forgot Password?</a>
      <div *ngIf="error_message">
        <span style="color:red">{{error_message}}</span>
      </div>
      <button mat-flat-button color="primary" class="submit-button" type="submit">LOG IN</button>
    </form>
  </mat-card>
</div>
