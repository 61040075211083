import { Component, OnInit } from '@angular/core';
import { MsalAccessService } from '../../auth/msal-service';
import { SearchService } from '../search.service';
import { Microsoft } from 'src/app/auth/microsoft.service';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css'],
})
export class IframeComponent implements OnInit {
  private baseUrl: string = 'https://app.adenin.com';

  isEnabledIframe: Boolean = false;
  query: string = '';

  constructor(private searchService: SearchService, private msalInstance: MsalAccessService, private microsoftAuth: Microsoft) {}

  ngOnInit(): void {
    this.searchService.isEnabledIframe.subscribe(isEnabledIframe => {
      this.removeFrame()
      this.isEnabledIframe = isEnabledIframe
      if(isEnabledIframe) {
        this.requestIframe(this.query)
      }
    })
    
    this.searchService.isLoading.subscribe(isLoading => {
      if(isLoading)
        this.removeFrame()
    });
    this.searchService.currentResults.subscribe(result => {
      if(result && result?.docs.length > 0 && this.microsoftAuth.isAuthenticated()) {
        this.query = result.docs?.[0]?.explain?.stems
        this.requestIframe(this.query)
      }
    });
    this.searchService.eraseEvent.subscribe((isErased) => {
      if(isErased)
        this.removeFrame()
    })
  }

  requestIframe(query: string) {
    if(this.msalInstance?.getIsTokenFromOauth()) {
      const token = this.microsoftAuth.getToken()
      this.addFrame(token, query)
    }
    else {
      const account = this.msalInstance.getMsalInstance().getAllAccounts()?.[0]
      const request = {
        scopes: [],
        account
      }
      this.msalInstance.getMsalInstance()
        .acquireTokenSilent(request)
        .then((token: any) => {
          this.addFrame(token, query)
        });
    }
  }

  getFrame() {
    return document.getElementById('frame');
  }

  removeFrame() {
    const frame = this.getFrame()
    if (frame) {
      frame.remove();
    }
  }


  addFrame(token: any, query: string) {
    const frame = document.createElement('iframe');
    frame.id = 'frame';
    frame.src = `${this.baseUrl}/authenticate?token=${token.accessToken}&tokensrc=office-365&returnUrl=${
      encodeURIComponent(`${this.baseUrl}/app/embedcardframe?query=${query}`)
    }`;
    frame.style.border = 'none'

    document.getElementById('frame-container')?.appendChild(frame);
  }
}