<div>
    <ng-template [ngIf]="result_url" [ngIfElse]="no_url">
        <a [href]="result_url" target="_blank" class="title" [innerHTML]="result_string"></a>
    </ng-template>
    <ng-template #no_url>
        <span class="title" [innerHTML]="result_title"></span>
    </ng-template>
    <ng-template [ngIf]="date">
        <span class="date">Publication Date: {{date}}</span>
    </ng-template>
    <!-- <ng-template>
        <span class="title" [innerHTML]="result_num"></span>
    </ng-template> -->
</div>
