import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CartItem, ShoppingCart } from './shared/shopping-cart';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  private shoppingCartSource = new BehaviorSubject<ShoppingCart>(new ShoppingCart());
  currentShoppingCart = this.shoppingCartSource.asObservable();

  constructor() { 
  }

  add(item: CartItem): void {
    const newCart = this.shoppingCartSource.value;
    newCart.items.set(item.id, item);
    this.changeCart(newCart);
  }

  remove(id: string): void {
    const newCart = this.shoppingCartSource.value;
    newCart.items.delete(id);
    this.changeCart(newCart);
  }

  changeCart(cart: ShoppingCart) {
    this.shoppingCartSource.next(cart);
  }
}
