import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-google-field',
  templateUrl: './google-field.component.html',
  styleUrls: ['./google-field.component.css']
})
export class GoogleFieldComponent implements OnInit {

  @Input() fields: Array<string> = [];
  @Input() doc: any;
  @Input() label?: string;

  query: string = '';

  constructor() { }

  ngOnInit(): void {
    for(let i = 0; i < this.fields.length; i++) {
      if(this.doc && this.doc[this.fields[i]]) {
        if(i != 0) {
          this.query += " ";
        }
        this.query += encodeURIComponent(this.doc[this.fields[i]]);
      }
    }
  }

}
