import { Component, Input, OnInit } from '@angular/core';
import {FieldView} from "../shared/config-response";

@Component({
  selector: 'app-currency-field',
  templateUrl: './currency-field.component.html',
  styleUrls: ['./currency-field.component.css']
})
export class CurrencyFieldComponent implements OnInit {

  @Input() fields: Array<string> = [];
  @Input() doc: any;
  @Input() item?: FieldView;
  divider: number = 1;
  symbol: string = '$';
  digitsInfo: string = '1.2-2';

  value?: number;

  constructor() { }

  ngOnInit(): void {
    if(this.item && this.item['divider']) {
      this.divider = this.item['divider'];
    }
    if(this.item && this.item['symbol']) {
      this.symbol = this.item['symbol'];
    }
    if(this.item && this.item['digits_info']) {
      this.digitsInfo = this.item['digits_info'];
    }
    const found = this.fields.find(field => {
      return (field in this.doc);
    });
    if(found) {
      this.value = parseInt(this.doc[found]) / this.divider;
    }
  }

}
