<!-- <div *ngIf="type==='swirl'" class="navBar">
  <a class="navbar-brand" href="#">
    <img title="logo" class="logo" style="display: block" src="assets/images/swirl-logo-rectangle.svg"/>
  </a>
</div> -->
<div class="blue-background"></div>
<div>
    <div class="header-container">
        <div class="logo" [ngStyle]="{'background-image': backgroundImage}" (click)="handleLogoClick()"></div>
        <app-box class="col" style="flex: 7; width: 100%;"></app-box>
        <profile-box class="col" style="flex: 1.5; width: 100%;"></profile-box>
        <!-- <app-reset class="col col-lg-1"></app-reset>
        <app-layout-toggle class="col"></app-layout-toggle> -->
    </div>
  <div>
    <app-iframe></app-iframe>
  </div>
  <div>
    <galaxy-chart *ngIf="isEnabledCharts"></galaxy-chart>
  </div>
  <div class="row">
    <!-- <app-swirl-mixer-filter *ngIf="type==='swirl'" class="col col-auto mb-1" [field]="'result_mixer'"
    [filters]="[
      ['RelevancyMixer', 'RelevancyMixer'],
      ['DateMixer', 'DateMixer'],
      ['RoundRobinMixer', 'RoundRobinMixer']
    ]"></app-swirl-mixer-filter> -->
    <div style="width: 100%; display: flex; justify-content: center; position: relative;">
      <div *ngIf="isLoading" class="loading-gif" id="loading-indicator" ></div>
    </div>
  </div>
  <!-- <div class="row">
    <app-breadcrumb class="col"></app-breadcrumb>
  </div> -->
  <div class="row mt-3">
    <app-facet-nav *ngIf="hasFacets" style="flex: 1.8; width: 100%; padding-left: 1rem"></app-facet-nav>
    <app-results class="col" style="flex: 6.5; width: 100%"></app-results>
    <div style="flex: 1.7; width: 100%"></div>
  </div>
</div>