import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../config.service';
import { Action, ActionParameterValue, ActionParameterValues, ConfigResponse } from '../shared/config-response';
import { SearchContext } from '../shared/search-context';
import { CartItem, ShoppingCart } from '../shared/shopping-cart';
import { ShoppingCartService } from '../shopping-cart.service';

@Component({
  selector: 'app-action-field-selector',
  templateUrl: './action-field-selector.component.html',
  styleUrls: ['./action-field-selector.component.css']
})
export class ActionFieldSelectorComponent implements OnInit {

  @Input() action: Action = new Action();
  @Input() actionParameterValues: ActionParameterValues = new ActionParameterValues();
  @Input() config: ConfigResponse = new ConfigResponse();
  @Input() searchContext: SearchContext = new SearchContext();
  @Input() cartAware: boolean = false;

  cart: ShoppingCart;
  selectableValues: Array<string>;
  displayCart: boolean = false;

  constructor(public activeModal: NgbActiveModal, private shoppingCartService: ShoppingCartService) {
    this.cart = new ShoppingCart();
    this.selectableValues = [];
  }

  ngOnInit(): void {
    this.shoppingCartService.currentShoppingCart.subscribe(cart => {
      this.cart = cart;
    });

    this.selectableValues = this.action.parameters.filter(parameter => {
      return parameter.type === 'actionParameterValues';
    }).map(parameter => {
      return parameter.value;
    });

    //make sure that every required value is pre-checked because we will be disabling the select/deselect
    for (let key of Object.keys(this.actionParameterValues)) {
      this.actionParameterValues[key].values.filter(value => {
        return value.required;
      }).forEach(value => {
        value.checked = 'checked';
      })
    }
  }

  check(actionParameterValue: ActionParameterValue) {
    if(actionParameterValue.checked === 'checked') {
      actionParameterValue.checked = '';
    } else {
      actionParameterValue.checked = 'checked';
    }
  }

  selectAll(selectableValue: string) {
    this.actionParameterValues[selectableValue].values.forEach(value => {
      value.checked = 'checked';
    });
  }

  clear(selectableValue: string) {
    this.actionParameterValues[selectableValue].values.filter(value => {
      return !value.required;
    }).forEach(value => {
      value.checked = '';
    });
  }

  tag(selectableValue: string, tag: string) {
    this.actionParameterValues[selectableValue].values.filter(value => {
      return value.tags && value.tags.includes(tag);
    }).forEach(value => {
      value.checked = 'checked';
    });
  }

  removeCartItem(id: string) {
    this.shoppingCartService.remove(id);
  }

  clearCart() {
    this.shoppingCartService.changeCart(new ShoppingCart());
  }

  export() {
    let urlstring = this.action.url + '?actionId=' + encodeURIComponent(this.action.id);
    this.action.parameters.forEach(parameter => {
      if(parameter.type === 'config') {
        urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.config[parameter.value]);
      }
      if(parameter.type === 'static') {
        urlstring += '&' + parameter.name + '=' + encodeURIComponent(parameter.value);
      }
      if(parameter.type === 'actionParameterValues') {
        this.actionParameterValues[parameter.value].values.filter(value => {
          return value.checked;
        }).forEach(value => {
          urlstring += '&' + parameter.name + '=' + encodeURIComponent(value.value);
        })
      }
      if(parameter.type === 'SearchContext') {
        if(parameter.value === 'refine') {
          const refinementMap = SearchContext.refinementMap(this.searchContext.refine);
          for(let entry of refinementMap.entries()) {
            urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.searchContext.facetString(entry[0], entry[1].join(this.searchContext.separator)));
          }
          const rangeRefinementMap = SearchContext.refinementMap(this.searchContext.refine, 'range');
          for(let entry of rangeRefinementMap.entries()) {
            urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.searchContext.rangeFacetString(entry[0], entry[1]));
          }
        } else {
          urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.searchContext[parameter.value]);
        }
      }
      if(parameter.type === 'cart') {
        const items = this.cart.items.values();
        for(let item of items) {
          urlstring += '&' + parameter.name + '=' + encodeURIComponent(item[parameter.value]);
        }
      }
    });
    window.open(urlstring, this.action.target);
    this.activeModal.close('Close click');
  }

  toggleCartDisplay() {
    this.displayCart = !this.displayCart;
  }
}
