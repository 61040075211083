import { Component, OnInit } from '@angular/core';
import {SearchContext} from '../shared/search-context';
import {Result} from '../shared/result';
import {SearchService} from '../search.service';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  result: Result;
  searchContext: SearchContext;
  page: number;
  pageSizes: Array<number>;
  isLoading: boolean;

  constructor(private searchService: SearchService, private configService: ConfigService) {
    this.result = new Result();
    this.searchContext = new SearchContext();
    this.page = 1;
    this.pageSizes = [];
    this.isLoading = false;
  }

  ngOnInit() {
    this.searchService.currentResults.subscribe( result => this.result = result);
    this.searchService.currentSearchContext.subscribe( searchContext => {
      this.searchContext = searchContext;
      this.page = (searchContext.start / searchContext.rows) + 1;
    });
    this.configService.getConfig().subscribe( config => {
      if(config) {
        this.pageSizes = config.pageSizes || [10, 25, 50];
      }
    });
    this.searchService.isLoading.subscribe(isLoading => this.isLoading = isLoading);
  }

  changePageSize(pageSize: number) {
    this.page = 1;
    this.searchService.changeRows(pageSize);
  }

  changePage(page: number) {
    this.searchService.changePage(page, (page - 1) * this.searchContext.rows);
  }

}
