import { Component, OnInit, Input } from '@angular/core';
import { FieldView } from '../shared/config-response';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-string-field',
  templateUrl: './string-field.component.html',
  styleUrls: ['./string-field.component.css']
})
export class StringFieldComponent implements OnInit {

  @Input() fields: Array<string> = [];
  @Input() doc: any;
  @Input() item?: FieldView;
  @Input() highlight: any;

  label: string;
  field: string;
  wrap: boolean;
  output: string;

  constructor() {
    this.label = '';
    this.field = '';
    this.output = '';
    this.wrap = false;
   }

  ngOnInit(): void {
    this.field = this.fields[0];
    const found = this.fields.find(field => {
      return (field in this.doc);
    });
    if (this.item?.label) {
      this.label = this.item.label;
    } 
    if (this.item?.wrap) {
      this.wrap = this.item.wrap;
    }
    if (this.doc[this.field] && this.doc[this.field] !== 'unknown') {
      this.doc[this.field] = new Date(this.doc[this.field])
      const datePipe = new DatePipe('en-US');
      this.doc[this.field] = datePipe.transform(this.doc[this.field], 'MMMM d, yyyy');
    }
    else if(this.doc[this.field] === 'unknown') {
      this.doc[this.field] = undefined
    }
  }
}
