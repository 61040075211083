export class ShoppingCart {
    items: Map<string, CartItem>;

    constructor() {
        this.items = new Map<string, CartItem>();            
    }
}

export class CartItem {
    [index: string]: any;
    id: string;

    constructor() {
        this.id = '';
    }
}