import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OauthService } from '../../auth/oauth2-service'
import { AuthService } from 'src/app/auth/auth.service';
import { MsalAccessService } from 'src/app/auth/msal-service';
import { Microsoft } from 'src/app/auth/microsoft.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'oidc-callback',
  templateUrl: './oidc-callback.component.html',
})
export class OidcCallbackComponent implements OnInit {

  constructor(private router: Router, private oauthService: OauthService, private authService: AuthService, private msalInstance: MsalAccessService, private msauthService: Microsoft) {}

  ngOnInit(): void {
    this.oauthService.events
      .pipe(filter((e: any) => e.type === 'token_received'))
      .subscribe(() => {
        const token = this.oauthService.getAccessToken()
        if(token) {
          this.authService.oidcLogin(token).subscribe(
            (data: any) => {
              if(data.token) {
                this.authService.setToken(data.token)
                this.authService.setUser(data.user)
                if(this.msalInstance.shouldUseTokenFromOauth) {
                  const oauthType = this.oauthService.getType()
                  if(oauthType === 'microsoft') {
                    this.msalInstance.setIsTokenFromOauth(true)
                    this.msauthService.setToken(token)
                  }
                }
                this.router.navigate(['/']);
              }
              else if(data.error) {
                console.log(data.error)        
              }
            },
            (error: any) => console.log(error)
          )
        }
      });
  }
}