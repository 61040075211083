import {Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CustomFieldService} from "../custom-field.service";
import {CustomFieldDirective} from "../custom-field.directive";
import {FieldView} from "../shared/config-response";

@Component({
  selector: 'app-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.css']
})
export class CustomFieldComponent implements OnInit, OnDestroy {


  @Input() fields: Array<string> = [];
  @Input() doc: any;
  @Input() highlight: any;
  @Input() item?: FieldView;

  @ViewChild(CustomFieldDirective, {static: true}) fieldHost!: CustomFieldDirective;

  customFieldType: string = '';

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private cfs: CustomFieldService) { }

  ngOnInit(): void {
    if(this.item && this.item['custom_field_type']) {
      this.customFieldType = this.item['custom_field_type'];
    }
    if(this.cfs.hasField(this.customFieldType)) {
      const component = this.cfs.getFieldComponent(this.customFieldType);
      if(component) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const viewContainerRef = this.fieldHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        componentRef.instance.item = this.item;
        componentRef.instance.doc = this.doc;
        componentRef.instance.highlight = this.highlight;
        componentRef.instance.fields = this.fields;
      }

    }
  }

  ngOnDestroy(): void {
  }

}

