import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  localStorageChanged: EventEmitter<string> = new EventEmitter<string>();

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
    document.body.classList.toggle("dark-theme");
    this.localStorageChanged.emit(value);
  }
}