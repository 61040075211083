import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service'; // Update the path as necessary

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.authService.logout().subscribe(
            (data: any) => {
              sessionStorage.clear();
              localStorage.clear();
              window.location.reload();
            },
            (logoutError: any) => {
              sessionStorage.clear();
              localStorage.clear();
              window.location.reload();
            }
          );
        }
        return throwError(error);
      })
    );
  }
}