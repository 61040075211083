<div>
    <ng-template [ngIf]="provider">
        <span class="authorship">{{provider}}</span>
    </ng-template>
    <ng-template [ngIf]="url">
        <a href="{{url}}" target="_blank" class="authorship-url">{{url}}</a>
    </ng-template>
    <!-- <ng-template [ngIf]="author">
        <span class="authorship">&ensp;|&ensp;{{author}}</span>
    </ng-template> -->
</div>