import { Component, Input, OnInit } from '@angular/core';
import {FieldView} from "../shared/config-response";

@Component({
  selector: 'app-json-field',
  templateUrl: './json-field.component.html',
  styleUrls: ['./json-field.component.css']
})
export class JsonFieldComponent implements OnInit {

  @Input() fields: Array<string> = [];
  @Input() doc: any;
  @Input() item?: FieldView;

  delimiter: string = ",";
  renderList: Array<JsonRenderer> = new Array<JsonArrayRenderer>();
  jsonItems: Array<string>= new Array<string>();
  labelMapping: any;

  constructor() {}

  transformObject(obj: any) {
    const transformedObject: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if(key) {
          const lastKey: any = key?.split('.')?.pop()?.replace(/[\[\]']+/g, '');
          const value = obj[key];
          transformedObject[lastKey] = value;
        }
      }
    }
    return transformedObject
  }

  ngOnInit(): void {
    this.doc['payload'] = this.transformObject(this.doc['payload'])

    if (this.item && this.item['delimiter']) {
      this.delimiter = this.item['delimiter'];
    }
    if (this.item && this.item['labels']) {
      this.labelMapping = this.item['labels'];
    }

    const found = this.fields.find(field => {
      return (field in this.doc);
    });
    if (found) {
      this.jsonItems;

      const content: Object = this.doc[found];

      this.populateRenderList(content, 0);

      this.renderList.forEach(renderElement => this.jsonItems.push(renderElement.render()));
    }
  }

  populateRenderList(content: Object, indentation: number): void {
    for(let [label, value] of Object.entries(content)) {
      //if a mapping for the label was provided in the config then use the mapping to override the label for this entry.
      label = this.lookupLabel(label);
      if (typeof(value) == "number") {
        this.renderList.push(new JsonNumberRenderer(label, value, indentation));
      } else if (typeof(value) == "string") {
        this.renderList.push(new JsonStringRenderer(label, value, indentation));
      } else if (typeof(value) == "boolean") {
        this.renderList.push(new JsonBooleanRenderer(label, value, indentation));
      } else if (typeof(value) == "object") {
        if (Array.isArray(value)) {
          this.renderList.push(new JsonArrayRenderer(label, value, indentation, this.delimiter));
        } else {
          const newContent: Map<string, any> = value;
          // Create a String Renderer for the label and Recursively render the rest of the JSON
          this.renderList.push(new JsonStringRenderer(label, "", indentation));
          this.populateRenderList(newContent, indentation + 2);
        }
      }
    }
  }

  lookupLabel(key: string): string {
    if(this.labelMapping && this.labelMapping[key]) {
      return this.labelMapping[key];
    }
    return key;
  }

}

export class JsonRenderer {
  label: string;
  value: any;
  indentation: number;

  constructor(label: string, indentation: number) {
    this.label = label;
    this.indentation = indentation;
  }

  render(): string {
    return " ".repeat(this.indentation) + "<strong>" + this.label + ":</strong> ";
  }
}

export class JsonNumberRenderer extends JsonRenderer {
  value: number;

  constructor(label: string, value: number, indentation: number) {
    super(label, indentation);
    this.value = value;    
  }

  render(): string {
    return super.render() + this.value.toString();
  }
}

export class JsonStringRenderer extends JsonRenderer {
  value: string;

  constructor(label: string, value: string, indentation: number) {
    super(label, indentation);
    this.value = value;
  }

  render(): string {
    return super.render() + this.value;
  }
}

export class JsonBooleanRenderer extends JsonRenderer {
  value: boolean;

  constructor(label: string, value: boolean, indentation: number) {
    super(label, indentation);
    this.value = value; 
  }

  render(): string {
    return super.render() + this.value.toString();
  }
}

export class JsonArrayRenderer extends JsonRenderer {
  value: Array<any>;
  delimiter: string;

  constructor(label: string, value: Array<any>, indentation: number, delimiter: string) {
    super(label, indentation);
    this.value = value;
    this.delimiter = delimiter;    
  }

  render(): string {
    const output = this.value.join(this.delimiter);
    return super.render() + output;
  }
}

