<!-- <div class="input-group mb-3">
  <input type="text" class="form-control" aria-label="facet filter" [ngModel]="textFilter.text" (ngModelChange)="onFilterChange($event)">
</div> -->
<div class="list-group facet-container">
  <label class="list-group-item list-group-item-action justify-content-between align-items-center facet-item"
         *ngFor="let bucket of facet['buckets']; let i = index;">
    <input class="me-1" type="checkbox" [checked]="bucket.checked" (change)="check(bucket)">
    <span>&nbsp;&nbsp;&nbsp;</span>
    <ng-template [ngIf]="type==='terms'">
      <ng-template [ngIf]="!reformat">{{bucket.label}} ({{bucket.count}})</ng-template>
      <ng-template [ngIf]="reformat && reformat['type'] === 'date'">{{bucket.label | date:reformat['format']:reformat['timezone']}}</ng-template>
    </ng-template>
    <ng-template [ngIf]="type==='range'"> {{bucket['rangeLabel']}}</ng-template>
    <!-- <span class="badge badge-primary badge-pill" style="float:right">{{bucket.count}}</span> -->
  </label>
</div>
<div class="d-md-flex justify-content-md-end pt-1">
  <!-- <button type="button" class="btn btn-primary mx-2" (click)="selectAll()">Select All</button> -->
  <p class="clear-all-button" (click)="clear()">Clear all</p>
  <!-- <button type="button" class="btn btn-primary mx-2" (click)="clear()" style="width: 100%">Reset All Filters</button> -->
  <!-- <button type="button" class="btn btn-primary mx-2" (click)="submit()">Submit</button> -->
</div>

