export class Result {
  docs: Array<any>;
  numFound: number;
  numRetrieved: number;
  start: number;
  maxScore: number;

  constructor() {
    this.docs = [];
    this.numFound = 0;
    this.numRetrieved = 0;
    this.start = 0;
    this.maxScore = 0;
  }
}
