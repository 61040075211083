import { Injectable, EventEmitter } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { SearchService } from './search.service';
import { Microsoft } from '../auth/microsoft.service';
import { Authenticators, AuthenticatorsObject } from '../auth';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket$: WebSocketSubject<any>;
  private url: string = ''
  private enabled: boolean = false
  time = new BehaviorSubject<any>(0)
  responseTime = this.time.asObservable()
  timeout = 60000
  timeoutText = "Timeout: No response from Generative AI."

  webSocketChanged: EventEmitter<[boolean, boolean]> = new EventEmitter<[boolean, boolean]>();

  constructor(private searchService: SearchService, private microsoftAuth: Microsoft) {
    this.url = ''
    this.timeout = 60000
    this.timeoutText = "Timeout: No response from Generative AI."
    this.socket$ = webSocket(this.url)
    this.enabled = false
    this.webSocketChanged = new EventEmitter<[boolean, boolean]>();
  }

  changeStatus(isEnabled: boolean, isNewSearch: boolean) {
    this.enabled = isEnabled
    this.webSocketChanged.emit([isEnabled, isNewSearch])
  }

  loadConfig(webSocketConfig: any): void {
    if(webSocketConfig) {
      if(webSocketConfig?.url && (webSocketConfig?.url?.startsWith('ws://') || webSocketConfig?.url?.startsWith('wss://')))
        this.url = webSocketConfig.url
      if(webSocketConfig?.timeout)
        this.timeout = webSocketConfig.timeout
      if(webSocketConfig?.timeoutText)
        this.timeoutText = webSocketConfig.timeoutText
    }
  }

  isEnabled(): Boolean {
    return this.enabled
  }

  disable(): void {
    this.enabled = false
  }

  async init(token: any, searchId: any, ragItems: any) {
    console.log(this.url)
    let url = `${this.url}?token=${token}&search_id=${searchId}`
    for (const authenticatorName of Authenticators) {
      const authenticator = new AuthenticatorsObject[authenticatorName]()
      if(authenticator) {
        let token = authenticator?.getToken()
        let isAuthenticated = authenticator?.isAuthenticated()
        if(!isAuthenticated) {
          token = await authenticator?.updateToken()
          isAuthenticated = authenticator?.isAuthenticated()
        }
        if(token && isAuthenticated)
          url += `&${authenticatorName.toLowerCase()}Token=${token}`
      }
    }
    if(ragItems && ragItems.length > 0)
      url += `&rag_items=${ragItems.join(',')}`
    this.socket$ = webSocket(url);
    return this.socket$;
  }

  receive() {
    return this.socket$;
  }

  send(message: string): void {
    this.socket$.next({ message });
  }
}