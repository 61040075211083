import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { Action, ConfigResponse } from '../shared/config-response';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionFieldSelectorComponent } from '../action-field-selector/action-field-selector.component';
import { SearchService } from '../search.service';
import { SearchContext } from '../shared/search-context';
import { ShoppingCartService } from '../shopping-cart.service';
import { ShoppingCart } from '../shared/shopping-cart';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {
  actions: Array<Action>;
  config: ConfigResponse;
  searchContext: SearchContext;
  cart: ShoppingCart;

  constructor(private configService: ConfigService, private searchService: SearchService, private shoppingCartService: ShoppingCartService, private modalService: NgbModal) {
    this.actions = [];
    this.config = new ConfigResponse();
    this.searchContext = new SearchContext();
    this.cart = new ShoppingCart();
  }

  ngOnInit(): void {
    this.configService.getConfig().subscribe(configResponse => {
      if(configResponse) {
        this.config = configResponse;
        if(configResponse.actions) {
          this.actions = configResponse.actions;
        }
      }
    });
    this.searchService.currentSearchContext.subscribe((searchContext: SearchContext) => {
      this.searchContext = searchContext;
    });
    this.shoppingCartService.currentShoppingCart.subscribe((cart: ShoppingCart) => {
      this.cart = cart;
    });
  }

  prepareAndApply(index: number) {
    const action = this.actions[index];
    if(action.useSelector) {
      this.selectParamsAndApply(action);
    } else {
      this.apply(action);
    }
  }

  selectParamsAndApply(action: Action) {
    const modalRef = this.modalService.open(ActionFieldSelectorComponent, { size: 'lg', scrollable: true });
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.actionParameterValues = this.config.actionParameterValues;
    modalRef.componentInstance.config = this.config;
    modalRef.componentInstance.searchContext = this.searchContext;
    modalRef.componentInstance.cartAware = -1 < action.parameters.findIndex(value => {
      return value.type === 'cart';
    });
  }

  apply(action: Action) {
    let urlstring = action.url + '?actionId=' + encodeURIComponent(action.id);
    action.parameters.forEach(parameter => {
      if(parameter.type === 'config') {
        urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.config[parameter.value]);
      }
      if(parameter.type === 'static') {
        urlstring += '&' + parameter.name + '=' + encodeURIComponent(parameter.value);
      }
      if(parameter.type === 'actionParameterValues') {
        const values = this.config.actionParameterValues[parameter.value].values;
        values.forEach(value => {
          urlstring += '&' + parameter.name + '=' + encodeURIComponent(value.value);
        })
      }
      if(parameter.type === 'SearchContext') {
        if(parameter.value === 'refine') {
          const refinementMap = SearchContext.refinementMap(this.searchContext.refine);
          for(let entry of refinementMap.entries()) {
            urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.searchContext.facetString(entry[0], entry[1].join(this.searchContext.separator)));
          }
          const rangeRefinementMap = SearchContext.refinementMap(this.searchContext.refine, 'range');
          for(let entry of rangeRefinementMap.entries()) {
            urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.searchContext.rangeFacetString(entry[0], entry[1]));
          }
        } else {
          urlstring += '&' + parameter.name + '=' + encodeURIComponent(this.searchContext[parameter.value]);
        }
      }
      if(parameter.type === 'cart') {
        const items = this.cart.items.values();
        for(let item of items) {
          urlstring += '&' + parameter.name + '=' + encodeURIComponent(item[parameter.value]);
        }
      }
    });
    window.open(urlstring, action.target);
  }
}
