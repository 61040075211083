import {Injectable} from '@angular/core';
import {HttpClient, HttpParameterCodec, HttpParams} from '@angular/common/http';
import {SearchContext} from './shared/search-context';
import {Observable} from 'rxjs';
import {SolrResponse} from './shared/solr-response';
import {ConfigService} from './config.service';
import {ConfigResponse, Fields} from './shared/config-response';
import {ResultMapping} from './shared/result-mapping';

@Injectable({
  providedIn: 'root'
})
export class SolrService {

  private selectUrl: string;

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.selectUrl = '';
    //TODO remove dependency on configService.
    configService.getConfig().subscribe(configResponse => {
      if(configResponse) {
        this.selectUrl = '/api/solr/' + configResponse.index + '/select';
      }
    });
  }

  select(searchContext: SearchContext): Observable<SolrResponse> {
    let data = new HttpParams({encoder: new HttpUrlEncodingCodec()});
    data = data
      .append('q', searchContext.query)
      .append('wt', 'json')
      .append('rows', searchContext.rows.toString())
      .append('start', searchContext.start.toString())
    if (searchContext.df) {
      data = data.append('df', searchContext.df);
    }
    if (searchContext.sort) {
      data = data.append('sort', searchContext.sort);
    }
    if(searchContext.defType) {
      data = data.append('defType', searchContext.defType);
    }
    if (searchContext.qOp) {
      data = data.append('q.op', searchContext.qOp);
    }
    if (searchContext.qf) {
      searchContext.qf.forEach(function (qfItem) {
        data = data.append('qf', qfItem);
      });
    }
    if (searchContext.refine) {
      //build a mapping of fields to selected values
      const refinementMap = SearchContext.refinementMap(searchContext.refine);
      for(let entry of refinementMap.entries()) {
        data = data.append('fq', searchContext.facetString(entry[0], entry[1].join(searchContext.separator)));
      }

      const rangeRefinementMap = SearchContext.refinementMap(searchContext.refine, 'range');
      for(let entry of rangeRefinementMap.entries()) {
        data = data.append('fq', searchContext.rangeFacetString(entry[0], entry[1]));
      }
    }
    if(searchContext.filters) {
      for(let value of searchContext.filters.values()) {
        data = data.append('fq', value);
      }
    }
    if(searchContext.baseFilters) {
      searchContext.baseFilters.forEach(function (filter) {
        data = data.append('fq', filter);
      });
    }
    if (searchContext.jsonFacet) {
      data = data.append('json.facet', JSON.stringify(searchContext.jsonFacet));
    }
    if (searchContext.fields) {
      data = data.append('hl', 'true')
        .append('hl.method', 'unified')
        .append('hl.encoder', 'html')
        .append('hl.tag.pre', '<span class="highlight">')
        .append('hl.tag.post', '</span>')
        .append('hl.qparser', 'lucene')
        .append('fl', Object.keys(searchContext.fields).join(","))
        .append('hl.fl', Object.keys(searchContext.fields).filter(field => {
          return searchContext.fields[field].highlightable;
        }).join(","));
    }
    if(searchContext.childFields) {
      Object.keys(searchContext.childFields).forEach(key => {
        data = data.append('fl', key + ':[subquery]');
        const value = searchContext.childFields[key];
        data = data.append(key + '.fl', value['fields'].join(","));
        data = data.append(key + '.q', `{!terms f=${value['joinKey']} v=$row.${value['parentKey']}}`);
        for(let filter of value['filters']) {
          data = data.append(key + '.fq', filter);
        }
        data = data.append(key + '.rows', value['rows']);
      });
    }
    if(searchContext.extraParams) {
      for(let key of searchContext.extraParams.keys()) {
        data = data.append(key, searchContext.extraParams.get(key)!);
      }
    }
    const result = this.http.post<SolrResponse>(this.selectUrl, data);
    return result;
  }

}

//The Default Angular Http Parameter encoder replaces all '+' with spaces.
//This implementation uses the standard javascript encode/decodeURIComponent functions
//to encode the parameters and can be used as a drop in replacement for the angular encoder.
export class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string { return encodeURIComponent(k); }
  encodeValue(v: string): string { return encodeURIComponent(v); }
  decodeKey(k: string): string { return decodeURIComponent(k); }
  decodeValue(v: string) { return decodeURIComponent(v); }
}
