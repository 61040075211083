import { Component, OnInit } from '@angular/core';
import {SearchService} from "../search.service";
import {SolrError} from "../shared/solr-response";
import { WebSocketService } from '../websockets.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  start: number;
  rows: number;
  numFound: number;
  qTime: number;
  qTimeSeconds: number;
  qSummaryTime: number;
  qSummaryTimeSeconds: number;
  date_retrieved: Date | null;
  isLoading: boolean;
  error?: SolrError;

  constructor(private searchService: SearchService, private webSocketService: WebSocketService) {
    this.start = 0;
    this.rows = 0;
    this.numFound = 0;
    this.qTime = 0;
    this.qTimeSeconds = 0;
    this.qSummaryTime = 0;
    this.qSummaryTimeSeconds = 0;
    this.date_retrieved = null;
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.searchService.currentSearchContext.subscribe(searchContext => {
      this.start = searchContext.start;
      this.rows = searchContext.rows;
    });
    this.searchService.currentResults.subscribe(results => {
      this.numFound = results.numFound;
    });
    this.searchService.currentResultHeader.subscribe(header => {
      if(header) {
        this.qTime = header.QTime;
        this.qTimeSeconds = this.qTime / 1000;
        this.date_retrieved = header.date_retrieved;
      }
    });
    this.searchService.currentError.subscribe(error => {
      this.error = error;
    })
    this.searchService.isLoading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.webSocketService.responseTime.subscribe(time => {
      this.qSummaryTime = time;
      this.qSummaryTimeSeconds = Math.round(this.qSummaryTime / 100) / 10;
    })
  }

}
