import {Component, Input, OnInit} from '@angular/core';
import {SearchService} from '../search.service';
import {ConfigService} from '../config.service';
import {FacetConfig} from '../shared/config-response';
import {Refinement} from "../shared/search-context";
import {Bucket, Facet} from "../shared/facets";
import { combineLatest, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { FacetFilter } from './facet-filter';

@Component({
  selector: 'app-facet',
  templateUrl: './facet.component.html',
  styleUrls: ['./facet.component.css']
})
export class FacetComponent implements OnInit {
  @Input() facetKey: string = '';

  isLoading: boolean;
  count: number;
  jsonFacet: FacetConfig;
  facet: Facet;
  staticBuckets: Array<Bucket>;
  textFilter: FacetFilter;
  type: string;
  reformat: any;

  constructor(private searchService: SearchService, private configService: ConfigService) {
    this.isLoading = false;
    this.count = 0;
    this.jsonFacet = new FacetConfig();
    this.facet = new Facet();
    this.staticBuckets = [];
    this.textFilter = new FacetFilter();
    this.type = '';
    this.reformat = null;
  }

  ngOnInit() {
    this.configService.getConfig().subscribe(config => {
      if(config) {
        this.jsonFacet = config.jsonFacet;
        this.type = this.jsonFacet[this.facetKey].type;
        this.reformat = this.jsonFacet[this.facetKey].reformat;
      }
    });
    combineLatest([this.searchService.currentFacets, this.searchService.currentSearchContext])
      .pipe(map(([currentFacets, currentContext]) => ({currentFacets, currentContext})))
      .subscribe(subscription => {
        this.count = subscription.currentFacets.count;
        this.facet = subscription.currentFacets[this.facetKey] as Facet;
        if(this.type === 'range') {
          this.facet.buckets.map(bucket => {
            // @ts-ignore
            const rangeBucket = this.jsonFacet[this.facetKey]['labels'][bucket.val];
            if(rangeBucket) {
              bucket['rangeLabel'] = rangeBucket['label'];
              bucket['rangeFilter'] = rangeBucket['filter'];
            }
          });
          //range facets do not calculate missing buckets, so we'll add it if a separate query facet for the missing bucket
          //is present.
          if(subscription.currentFacets[this.facetKey + '-missing']) {
            const missingBucket = new Bucket();
            const missingFacet = subscription.currentFacets[this.facetKey + '-missing'] as Facet;
            missingBucket.val = 'Blank';
            missingBucket['rangeLabel'] = 'Blank';
            missingBucket['rangeFilter'] = 'Blank';
            missingBucket.count = missingFacet.count as number;
            if(!this.facet.buckets.find(bucket => {
              return bucket.val === 'Blank';
            })) {
              this.facet.buckets.push(missingBucket);
              this.facet.buckets.sort((a, b) => b.count - a.count);
            }
          }
        }
        if(this.facet?.missing && this.facet?.missing?.count) {
          const missingBucket = new Bucket();
          missingBucket.val = 'Blank';
          missingBucket.count = this.facet.missing.count;
          if(!this.facet.buckets.find(bucket => {
            return bucket.val === 'Blank';
          })) {
            this.facet.buckets.push(missingBucket);
            this.facet.buckets.sort((a, b) => b.count - a.count);
          }
        }
        this.staticBuckets = [];
        this.textFilter = new FacetFilter();
        if (this.facet && this.facet?.buckets) {
          this.facet.buckets = this.facet.buckets?.filter(bucket => bucket.count > 0)
          this.facet.buckets = this.facet.buckets.sort((a: any, b: any) => a.count === b.count ? a.label.localeCompare(b.label) : b.count - a.count)
          this.facet.buckets.forEach(bucket => {
            this.staticBuckets.push(bucket);
          });
        }
        subscription.currentContext.refine.forEach(refinement => {
          if(refinement.label === this.facetKey) {
            this.facet.buckets.forEach(bucket => {
              if(refinement.type === 'terms' && refinement.value === bucket.val) {
                bucket['checked'] = 'checked';
              }
              if(refinement.type === 'range' && refinement.value === bucket['rangeFilter']) {
                bucket['checked'] = 'checked';
              }
            });
          }
        });
      });
    this.searchService.isLoading.subscribe(isLoading => this.isLoading = isLoading);
  }

  refine(field: string, value: string) {
    const facet = this.jsonFacet[field]
    if(facet) {
      this.searchService.refine(Refinement.toRefinement(facet.facetField, field, value));
    }
  }

  onFilterChange(text: string) {
    this.textFilter.text = text;
    if(this.type === 'range') {
      this.staticBuckets.forEach(bucket =>  {
        if((bucket['rangeLabel'] as string).toLowerCase().includes(this.textFilter.text.toLowerCase())) {
          bucket.filtered = false;
        } else {
          bucket.filtered = true;
        }
      });
    } else {
      this.staticBuckets.forEach(bucket =>  {
        if(bucket.val.toLowerCase().includes(this.textFilter.text.toLowerCase())) {
          bucket.filtered = false;
        } else {
          bucket.filtered = true;
        }
      });
    }
    this.facet.buckets = this.staticBuckets.filter(bucket => !bucket.filtered);
  }

  check(bucket: Bucket) {
    this.searchService.clearSelectedItems()
    if(bucket['checked'] === 'checked') {
      bucket['checked'] = '';
    } else {
      bucket['checked'] = 'checked';
    }
    this.submit()
  }

  selectAll() {
    for(let i = 0; i < this.facet.buckets.length; i++) {
      this.facet.buckets[i]['checked'] = 'checked';
    }
  }

  clear() {
    for(let i = 0; i < this.facet.buckets.length; i++) {
      this.facet.buckets[i]['checked'] = '';
    }
    const facet = this.jsonFacet[this.facetKey];
    this.searchService.refineMultiple(facet.facetField, new Array<Refinement>());
  }

  submit() {
    const refinements = new Array<Refinement>();
    const facet = this.jsonFacet[this.facetKey];
    if(facet) {
      for(let i = 0; i < this.facet.buckets.length; i++) {
        if(this.facet.buckets[i]['checked'] === 'checked') {
          if(this.type === 'range') {
            // @ts-ignore
            refinements.push(Refinement.toRefinement(facet.facetField, this.facetKey, this.facet.buckets[i]['rangeFilter'], 'range', this.facet.buckets[i]['rangeLabel']));
          } else {
            refinements.push(Refinement.toRefinement(facet.facetField, this.facetKey, this.facet.buckets[i].val, 'terms', this.facet.buckets[i].label));
          }
        }
      }
    }
    this.searchService.refineMultiple(facet.facetField, refinements);
  }
}
