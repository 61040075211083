export class ResultMapping {
  title: string;
  teaser: string;
  text: string;

  constructor() {
    this.title = '';
    this.teaser = '';
    this.text = '';
  }
}
