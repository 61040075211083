<form class="col form-group" [formGroup]="searchForm"  (ngSubmit)="onSubmit()" style="height: auto; min-height: 80px; margin-bottom: 0; margin-top: 20px;">
    <div class="row">
        <mat-form-field appearance="outline" class="responsive-form">
            <mat-select class="mat-select" [formControl]="selectedSources" (selectionChange)="onSourcesSelect($event)" multiple>
                <mat-option *ngFor="let source of sources" [value]="source.value" [class.mat-option]="true" [class.default-source]="source.value === 'default'">
                {{source.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="col">
            <mat-form-field class="col mr-4 input" appearance="outline" style="margin-bottom: -3rem; margin-top: -1rem; height: 100%;">
                <input matInput class="mat-input" formControlName="query" [value]="searchContext.query" [placeholder]="searchBarPlaceholder">
            </mat-form-field>
            <div class="ai-response-option" style="margin-bottom: -2.5rem; margin-top: 1.5rem; margin-left: 0.7rem;">
                <mat-slide-toggle formControlName="checkedGenerateAiResponse" (change)="toggleChange($event)" class="custom-slide-toggle" [disabled]="isRAGButtonDisabled">
                    <span class="authenticator-label">
                        {{ aiResponseLabel }}
                    </span>
                </mat-slide-toggle>
            </div>
        </div>
        <button mat-flat-button color="primary" class="submit-button" type="submit" [disabled]="isSearchButtonDisabled" [ngClass]="{'disabled-button': isSearchButtonDisabled}">{{ searchButtonLabel }}</button>
    </div>
</form>
