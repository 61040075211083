import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appFieldHost]'
})
export class CustomFieldDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
