import { Component, OnInit, Input } from '@angular/core';
import { FieldView } from '../shared/config-response';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-title-field',
  templateUrl: './title-field.component.html',
  styleUrls: ['./title-field.component.css']
})
export class TitleFieldComponent implements OnInit {

  @Input() fields: Array<string> = [];
  @Input() doc: any;
  @Input() item?: FieldView;

  result_num: number = 0;
  result_title: string = "";
  result_url: string = "";
  result_string: string = "";
  date: string | null | undefined = "";

  constructor() {}

  ngOnInit(): void {
    if (this.doc[this.fields[0]]) {
      this.result_title = this.doc[this.fields[0]];
    }
    if (this.doc[this.fields[1]]) {
      this.result_num = this.doc[this.fields[1]];
    }
    if (this.doc[this.fields[2]] != "") {
      this.result_url = this.doc[this.fields[2]];
    }
    if (this.doc['date_published_display'] && this.doc['date_published_display'] !== '')
      this.date = this.doc['date_published_display']
    else if (this.doc['date_published'] && this.doc['date_published'] !== 'unknown') {
      this.date = this.doc['date_published']
      const datePipe = new DatePipe('en-US');
      this.date = datePipe.transform(this.date, 'MMMM d, yyyy');
    }
    else if(this.doc['date_published'] === 'unknown') {
      this.date = undefined
    }
    
    if (this.result_url)
      this.result_string = this.result_num + ". " + this.result_title;
    else
      this.result_title = this.result_num + ". " + this.result_title;
  }

}
