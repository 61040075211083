import {ConfigResponse, Fields} from "./config-response";

export class SearchContext {
  [index: string]: any;
  query: string;
  refine: Array<Refinement>;
  facet: {
    query: Array<string>;
    field: Array<string>;
  }
  rows: number;
  start: number;
  defType?: string;
  filters: Map<string, string>;
  baseFilters: Array<string>;
  separator: string;
  jsonFacet: any;
  df: string;
  qOp: string;
  qf: Array<string>;
  fields: Fields;
  childFields: any;
  extraParams: Map<string, string>;
  type: string;
  page: number;
  searchId: string;
  providers: Array<string> | Array<number>;
  connectorsNames: Array<string>;
  rag: boolean;

  constructor() {
    this.query = '';
    this.refine = [];
    this.facet = {
      query: [],
      field: []
    };
    this.rows = 10;
    this.start = 0;
    this.filters = new Map<string, string>();
    this.baseFilters = [];
    this.separator = '|_sg_|';
    this.df = '';
    this.qOp = '';
    this.qf = [];
    this.fields = {};
    this.extraParams = new Map<string, string>();
    this.type = '';
    this.page = 1;
    this.searchId = '';
    this.providers = [];
    this.connectorsNames = [];
    this.rag = false;
  }

  static newSearchContext(config: ConfigResponse): SearchContext {
    const searchContext = new SearchContext();
    if(config && config.separator) {
      searchContext.separator = config.separator;
    }
    if(config && config.pageSizes && config.pageSizes.length > 0) {
      searchContext.rows = config.pageSizes[0];
    }
    if(config && config.defaultFilters) {
      searchContext.filters = new Map(Object.entries(config.defaultFilters));
    }
    if(config) {
      searchContext.defType = config.defType;
      searchContext.baseFilters = config.filters;
      searchContext.df = config.df;
      searchContext.qOp = config.qOp;
      searchContext.qf = config.qf;
      searchContext.jsonFacet = config.jsonFacet;
      searchContext.fields = config.fields;
      searchContext.childFields = config['childFields'];
      searchContext.type = config['type'];
    }
    return searchContext;
  }

  facetString(field:string, values:string) {
    const valueArray = values.split(this.separator);
    const termsFilter = valueArray.filter(v => {
      return v !== 'Blank';
    }).map(v => {
      return v.replace(/'/g, "\\'");
    }).join(this.separator);
    const blankFilter = valueArray.find(v => {
      return v === 'Blank';
    });
    if(termsFilter && blankFilter) {
      return `(-${field}:* AND *:*) OR {!terms f='${field}' v='${termsFilter}' separator='${this.separator}'}`;
    }
    if(blankFilter) {
      return `-${field}:*`;
    }
    return `{!terms f='${field}' v='${termsFilter}' separator='${this.separator}'}`;
  }

  rangeFacetString(field:string, values:Array<string>) {
    return values.map(value => {
      if(value === 'Blank') {
        return `(-${field}:* AND *:*)`;
      }
      return `${field}:${value}`;
    }).join(' OR ');
  }

  static refinementMap(refinements: Array<Refinement>, type: string ='terms') {
    const refinementMap = new Map<string, Array<string>>();
    refinements.forEach(function (refinement) {
      if(refinement.type !== type) {
        return;
      }
      if(!refinementMap.has(refinement.field)) {
        refinementMap.set(refinement.field, new Array<string>());
      }
      refinementMap.get(refinement.field)?.push(refinement.value);
    });
    return refinementMap;
  }
}

export class Refinement {
  field: string;
  label: string;
  value: string;
  displayValue: string;
  type: string;

  constructor() {
    this.field = '';
    this.label = '';
    this.value = '';
    this.type = '';
    this.displayValue = '';
  }

  static toRefinement(field: string, label: string, value: string, type:string = 'terms', displayValue: string = value): Refinement {
    const refinement = new Refinement();
    refinement.field = field;
    refinement.label = label;
    refinement.value = value;
    refinement.type = type;
    refinement.displayValue = displayValue;
    return refinement;
  }

}
