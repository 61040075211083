<div class="modal-header">
  <h3 class="modal-title">Cart contents</h3>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">     
  <div class="row" *ngIf="cartAware">
    <a class="col-auto" style="cursor: pointer;" (click)="toggleCartDisplay()">Selected Documents</a>
  </div>                     
  <div class="row selector" [class.cartAware]="cartAware" *ngFor="let selectableValue of selectableValues">
    <div class="col col-6 h-100 d-inline-block">
      <ul class="list-group" *ngIf="displayCart">
        <li class="list-group-item list-group-item-action justify-content-between align-items-center m-0" *ngFor="let item of cart.items | keyvalue">
          <span>{{item.key}}</span>
          <button type="button" class="btn float-right p-0" (click)="removeCartItem(item.key)">
            <span>x</span>
          </button>
        </li>
      </ul>
      <div class="row my-4">
        <div class="col-6 justify-content-between align-items-center">
          <button type="button" class="btn btn-secondary" (click)="selectAll(selectableValue)">Select All</button>
        </div>
        <div class="col-6 justify-content-between align-items-center">
          <button type="button" class="btn btn-secondary" (click)="clear(selectableValue)">Deselect All</button>
        </div>
      </div>
      <div class="row my-4" *ngFor="let tagger of actionParameterValues[selectableValue].taggers">
        <div class="col justify-content-between align-items-center">
          <button type="button" class="btn btn-secondary btn-lg btn-block" (click)="tag(selectableValue, tagger.tag)">{{tagger.label}}</button>
        </div>        
      </div>
    </div>
    <div class="col col-6 h-100 d-inline-block selections border-left border-secondary">
      <div class="list-group">          
        <label class="list-group-item justify-content-between align-items-center"
        *ngFor="let actionParameterValue of actionParameterValues[selectableValue].values" [class.list-group-item-action]="!actionParameterValue.required">          
          {{actionParameterValue.label}}
          <input class="me-1 float-right" type="checkbox" [checked]="actionParameterValue.checked" (change)="check(actionParameterValue)" [attr.disabled]="actionParameterValue.required">
        </label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  <button type="button" class="btn btn-danger" (click)="clearCart()">Empty Cart</button>
  <button type="button" class="btn btn-primary" (click)="export()">Export to CSV</button>
</div>
